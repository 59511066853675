import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery-slim';
import { get } from 'lodash';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';

import { setSearch } from "../../actions/index";

import Media from "../../components/Media";
import Link from '../../components/Link';
import Logo from './Logo';
import { Search, Chevron } from '../../helpers/Icons';
import { Button } from '../../components/Field';

import style from './style.module.css';
import Placeholder from '../../components/Placeholder';
import { getCookie } from '../../helpers/storage';
import Center from '../../components/Center';

const Header = () => {
    const [height, setHeight] = useState(0);
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [mobileMenu, setMobilemenu] = useState(false);
    const [clickedMenu, setClickMenu] = useState(false);
    const isLoggedin = getCookie();
    const { search, token, user: { data } } = useSelector(state => state.storage);
    const { fetching = false, status } = useSelector((state) => get(state, 'content.config', {}));
    const { links, header_logo_dark = "", header_logo_light = "", button = {}, signup_button = {}, header_mobile_logo_dark, header_mobile_logo_light, header_tablet_logo_dark, header_tablet_logo_light } = useSelector((state) => get(state, 'content.config.data.data.header', {}));
    const { pathname = "" } = useLocation();
    let bhTimeout = null;
    const campaignPages = [`/${process.env.REACT_APP_CAMPAIGN_URL}`, `/${process.env.REACT_APP_PODIUM_CAMPAIGN_URL}`];
    const isCampaign = (campaignPages.indexOf(pathname) !== -1) ? true : false;
    const pageState = useSelector((state) => get(state, `content.page`, {}));
    const pageStatus = get(pageState, 'status');
    const pageData = get(pageState, 'data', {});
    let customHeaderData = {};

    if (get(pageData, 'widgets', []).length) {
        customHeaderData = get(pageData, 'widgets', []).find((w => w.type === "campaign_form"));
    }
    const { profile = null, first_name = "", image = "" } = data;

    const updateHeight = () => {
        setHeight($(`.${style.header}`).outerHeight());
    }

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        updateHeight();
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            updateHeight();
        }, 500);
    }, [status, pageStatus, first_name, fetching, image])

    useEffect(() => {
        if (popup) {
            $('html').addClass('overflow-hidden');
        } else {
            $('html').removeClass('overflow-hidden');
        }

        if (popup) {
            bhTimeout = setTimeout(() => {
                $('html').addClass('black-header');
            }, 801);
        } else {
            $('html').removeClass('black-header');
        }

        return () => {
            clearTimeout(bhTimeout);
        }
    }, [popup])

    const toggleMobilemenu = (toggle = false) => {
        if (toggle === false) {
            $(`.${style.mobileMenu}`).removeClass(style.show)
            $('html').removeClass('overflow-hidden');
            setTimeout(() => {
                setMobilemenu(false);
            }, 1000);
        } else {
            setMobilemenu(true);
            setTimeout(() => {
                $(`.${style.mobileMenu}`).addClass(style.show)
                $('html').addClass('overflow-hidden');

            }, 100);
        }
    }

    useEffect(() => {
        setPopup(false);
        dispatch(setSearch(false));
        toggleMobilemenu(false);
        setClickMenu(false);
    }, [pathname]);

    const getSubMenuHtml = (submenu) => {
        let html = null;
        let isAnySubmenuActive = false;
        if (submenu && submenu.length > 0) {
            html = (<div className={style.submenu}>
                {submenu.map((sItem) => {
                    const subLink = get(sItem, 'url', '');
                    const submenuActive = window.location.pathname.replace(process.env.REACT_APP_URL_PREFIX, '') === subLink;
                    if (submenuActive && isAnySubmenuActive === false) {
                        isAnySubmenuActive = true;
                    }
                    return (
                        <Link key={subLink} to={subLink} target={get(sItem, 'target')} className={`${style.submenuItem}${submenuActive ? ` ${style.active}` : ``}`}><span>{get(sItem, 'label')}</span></Link>
                    )
                })}
            </div>
            )
        }
        return [html, isAnySubmenuActive];
    }

    const NavLink = () => {
        return <>
            {links && links.length > 0 && links.map(({ url = "#", label = "", target = "_self", submenu = [], membership_plans = [] }, l) => {
                const trimmedPathname = pathname.split("/")[1] || "home";
                const trimmedLink = url.replaceAll("/", "");
                if (url === "#" || url === null || url === false || (membership_plans.length !== 0 && !membership_plans.includes(get(data, "membership.membership_id", 0)))) {
                    return null;
                }

                let submenuHtml = getSubMenuHtml(submenu);
                let isAnySubmenuActive = submenuHtml[1];
                const hasSubmenu = submenu && submenu.length > 0;
                return (
                    <div className={`link-item-${l + 1} ${style.linkItem}${hasSubmenu ? ` ${style.hasSubmenu}` : ``}`} key={l}>
                        <Link to={url} target={target} className={`${style.link}${trimmedPathname === trimmedLink || isAnySubmenuActive ? ` ${style.active}` : ``}`}>{label}</Link>
                        {submenu && submenu.length > 0 &&
                            <Link className={style.chevronArrow}
                                onClick={() => {
                                    if ($(`.link-item-${l + 1}`).hasClass(style.showSubMenu)) {
                                        $(`.link-item-${l + 1}`).removeClass(style.showSubMenu);
                                    } else {
                                        $(`.link-item-${l + 1}`).addClass(style.showSubMenu);
                                    }
                                }}
                            >
                                <ReactSVG src={Chevron} />
                            </Link>
                        }
                        {submenuHtml[0]}
                    </div>
                )
            })}
        </>
    }

    const resize = () => {
        window.dispatchEvent(new Event('resize'));
    }

    if (pathname === "/authorize" || pathname === "/login") {
        return null
    }
    return (
        <>
            <div className={`${pathname.includes("/register") ? `${style.register} ` : ''}header ${style.header}${mobileMenu ? ` ${style.blackBar}` : ''}${isCampaign ? ` ${style.campaign}` : ""} ${search ? style.fixedHeader : ''}`}>
                <Center>
                    <div className={style.headerCenter}>
                        {
                            fetching === true && (
                                <div className={style.headerLoader}>
                                    <div className={style.logo}>
                                        <Placeholder type="logo" />
                                    </div>
                                    <div className={style.menus}>
                                        <Placeholder type="title" />
                                        <Placeholder type="title" />
                                        <Placeholder type="title" />
                                        <Placeholder type="title" />
                                    </div>
                                    <div className={(isLoggedin === false || isLoggedin === "") ? style.button : style.profile}>
                                        <Placeholder />
                                    </div>
                                </div>
                            )
                        }
                        {
                            fetching === false && (
                                <>
                                    {
                                        token && (
                                            <Link onClick={() => { toggleMobilemenu(!mobileMenu); setClickMenu(!clickedMenu) }} className={`${style.hamburgerMenu}${clickedMenu ? ` ${style.toggled}` : ''}`}>
                                                <div></div>
                                            </Link>
                                        )
                                    }
                                    <Link to={isCampaign ? (get(customHeaderData, "campaign_logo_link.url", '#')) : "/"} className={style.logo} onClick={() => { setPopup(false) }}>
                                        <span className={style.desktopLogo}>
                                            <Logo light={header_logo_light} dark={header_logo_dark} onLoad={resize} size="0x220" />
                                        </span>
                                        <span className={style.tabletLogo}>
                                            <Logo light={header_tablet_logo_light} dark={header_tablet_logo_dark} onLoad={resize} size="0x220" />
                                        </span>
                                        <span className={style.mobileLogo}>
                                            <Logo light={header_mobile_logo_light} dark={header_mobile_logo_dark} onLoad={resize} size="0x110" />
                                        </span>
                                    </Link>
                                    {
                                        token && (
                                            <div className={`${style.headerMenu}`}>
                                                <NavLink />
                                            </div>
                                        )
                                    }
                                    <div className={`${style.signInButton}`}>
                                        {
                                            isCampaign === true && get(customHeaderData, "campaign_button_link.label") && (<Button color="transparent" link={get(customHeaderData, "campaign_button_link.url", '#')} label={get(customHeaderData, "campaign_button_link.label", "Sign In")} />)
                                        }
                                        {
                                            !token && isCampaign === false && (
                                                <>
                                                    <Button httpTarget={'_self'} link={get(signup_button, "url", '#')} label={get(signup_button, "label", "Sign Up")} />
                                                    <Button httpTarget={'_self'} link={get(button, "url", '#')} label={get(button, "label", "Sign In")} />
                                                </>
                                            )
                                        }
                                        {
                                            token && isCampaign === false && (
                                                <div className={`${style.profileSection}`}>
                                                    <Link className={`${style.search}`} onClick={() => { dispatch(setSearch()) }}>
                                                        <ReactSVG src={Search} />
                                                    </Link>
                                                    <Link className={`${style.icon}`} to={`/profile`}>
                                                        {
                                                            profile === null && image !== "" ?
                                                                <Media src={image} size="96x96" lazy={false} brokenIcon={`fa-user`} /> :
                                                                <Media brokenIcon={`fa-user`} src={image} size="96x96" type="image" lazy={false} />
                                                        }
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Center>
            </div>
            {pathname.includes('contact') && (
                <div style={{ height }}></div>
            )}
            <div className={style.heightBar} style={{ height }}></div>
            {
                token && mobileMenu === true && pathname !== "/register" && <div className={style.mobileMenu} style={{ top: `${height}px`, height: `calc(100% - ${height}px)` }}>
                    <div className={style.container}>
                        <div className={style.items}>
                            <NavLink />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Header;